import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import { useStyles } from './styles';
import { Link } from 'gatsby';

const NotFound: React.FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h2">Page Not Found</Typography>
      <Typography className={classes.subtitle} variant="h4">
        You just hit a route that doesn&#39;t exist...
        <br /> but we have plenty more!
      </Typography>
      <Button disableElevation variant="contained" color="primary" component={Link} className={classes.link} to="/">
        Start here
      </Button>
    </Container>
  );
};

export default NotFound;
