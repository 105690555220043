import React from 'react';
import Layout from '../containers/Layout';
import NotFound from '../containers/NotFound';

const NotFoundPage: React.FC = () => (
  <Layout title="Solvana | Page Not Found" hideFooter>
    <NotFound />
  </Layout>
);

export default NotFoundPage;
