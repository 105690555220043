import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(12, 3, 6),
      textAlign: 'center',
      '& hgroup': {
        background: `-webkit-linear-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      },
    },
    subtitle: {
      marginTop: theme.spacing(3),
      color: Colors.GreyTwo,
    },
    link: {
      marginTop: theme.spacing(3),
    },
  }),
);
